import {Box, Checkbox, FormControlLabel, TextField} from "@mui/material";
import {useEffect, useState} from "react";

const Cashier = ({setter}) => {
    const [showCashier, setShowCashier] = useState(false)
    const [cashierUrl, setCashierUrl] = useState(window.location)

    useEffect(() => {
        if (!showCashier) {
            setter("");
        } else {
            setter(cashierUrl);
        }
    }, [cashierUrl, setter, showCashier]);

    return (
        <Box display="flex flex-column" sx={{width: 500, mt: "2rem"}}>
            <FormControlLabel control={
                <Checkbox checked={showCashier} onChange={() => setShowCashier(!showCashier)}/>
            } label="Add cashier"/>
            {
                showCashier ?
                    <TextField fullWidth id="casier"
                               sx={{mt: ".5rem"}}
                               label="Casier" variant="outlined"
                               value={cashierUrl} type="text"
                               onChange={(e) => setCashierUrl(e.target.value)}/>
                    : null
            }
        </Box>)

}

export {
    Cashier
}