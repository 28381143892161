import {Fragment, useEffect, useState} from "react";
import {v4 as uuid} from "uuid";
import {Box, Button, TextField} from "@mui/material";
import {FreeBetsControl} from "../FreeBetsControl";
import {GameIframe} from "../GameIframe";
import {CashRunCheats} from "../CashRunCheats";
import {Cashier} from "../Cashier/cashier";
import {RecursiveRun} from "../RecursiveRun";

const DemoIntegrator = ({link, setLink, gameURL, gameName, currency, language, env}) => {
    const [userID, setUserID] = useState(uuid());
    const [initBalance, setInitBalance] = useState(1000000)
    const [isIframeOpen, setIsIframeOpen] = useState(false)
    const [cashierUrl, setCashierUrl] = useState(null)

    const toggleIframe = () => {
        setIsIframeOpen(!isIframeOpen)
    }

    useEffect(() => {
        let url = `${gameURL}?user_id=${userID}&init_balance=${initBalance}&currency=${currency}&lang=${language}&integrator=demo&operator=demo`

        if (cashierUrl) {
            url += `&cashier=${cashierUrl}`
        }


        setLink(url)
    }, [userID, initBalance, cashierUrl, gameURL, currency]);

    return (
        <Fragment>
            <Box display="flex" sx={{width: 500, mt: "2rem"}}>
                <TextField fullWidth id="outlined-basic" label="User ID" variant="outlined" value={userID}/>
                <Button contained onClick={() => setUserID(uuid())} sx={{ml: "2rem"}}>
                    Regenerate
                </Button>
            </Box>
            <Box display="flex" sx={{width: 500, mt: "2rem"}}>
                <TextField fullWidth id="initBalance" label="Init Balance" variant="outlined" value={initBalance}
                           type="number"
                           onChange={(e) => setInitBalance(e.target.value)}/>
            </Box>

            <Cashier setter={setCashierUrl}/>


            <RecursiveRun url={link}/>

            <FreeBetsControl userID={userID} game={gameName} env={env} currency={"usd"}/>

            <Box component="div"
                 sx={{'& > :not(style)': {m: 1, minWidth: '25ch'}, mt: "3rem", display: 'flex', width: "100%"}}
                 autoComplete="off">
                <Button variant="contained" onClick={toggleIframe}>
                    Run in iframe
                </Button>


            </Box>

            <GameIframe link={link} isHidden={!isIframeOpen}/>
            <CashRunCheats name={gameName} gameURL={gameURL} externalUserID={userID} currency={currency}/>
        </Fragment>
    )
}

export {
    DemoIntegrator
}