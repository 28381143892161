import {Fragment, useState} from "react";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControl, InputLabel,
    MenuItem,
    Popover,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {BoxRow} from "../Box";
import {predict} from "../../api/cash_run";

const BettingRewardStructure = {
    32: [16, 8, 1],
    31: [16, 8, 1],
    30: [15, 8, 1],
    29: [15, 7, 1],
    28: [14, 7, 1],
    27: [14, 7, 1],
    26: [13, 7, 1],
    25: [13, 6, 1],
    24: [12, 6, 1],
    23: [12, 6, 1],
    22: [11, 6, 1],
    21: [11, 5, 1],
    20: [10, 5, 1],
    19: [10, 5, 1],
    18: [9, 5, 1],
    17: [9, 4, 1],
    16: [8, 4, 1],
    15: [8, 4, 1],
    14: [7, 4, 1],
    13: [7, 3, 1],
    12: [6, 3, 1],
    11: [6, 3, 1],
    10: [5, 3, 1],
    9: [5, 2, 1],
    8: [4, 2, 1],
    7: [4, 2, 1],
    6: [3, 2, 1],
    5: [3, 2, 1],
    4: [3, 2, 1],
}

const defaultUser = () => {
    return {
        "elo": 2000,
        "game_count": 100,
        "lost_round": 1,
        "orders": [0, 0, 0]
    }
}

const Min = 4
const Max = 32

const MinElo = 0
const MaxElo = 100 * 1000

const EloPredictor = ({apiPath}) => {
    const [users, setUsers] = useState([defaultUser(), defaultUser(), defaultUser(), defaultUser()])
    const [lastResponse, setLastResponse] = useState([])
    const [error, setError] = useState("")
    const [roundTypes, setRoundTypes] = useState([1, 2, 3])

    const localPredict = () => {
        setError("")
        setLastResponse([])

        predict(apiPath, users, roundTypes)(setLastResponse, setError)
    }

    const addUser = () => {
        if (users.length < Max) {
            setUsers([...users, defaultUser()])
        }
    }

    const updateUser = (user, index) => {
        setUsers([...users.slice(0, index), user, ...users.slice(index + 1)])
    }

    const removeUser = () => {
        if (users.length > Min) {
            setUsers(users.slice(0, users.length - 1))
        }
    }

    const updateRoundTypesArray = (index) => (e) => {
        const v = parseInt(e.target.value, 10)
        if (isNaN(v)) {
            return
        }

        const rt = [...roundTypes]
        rt[index] = v

        setRoundTypes(rt)
    }

    const updateGameCountUser = (index, field) => (e) => {
        let u = {...users[index]}
        const pi = parseInt(e.target.value, 10)
        if (isNaN(pi)) {
            u[field] = 0
        } else {
            u[field] = pi
        }


        updateUser(u, index)
    }

    const updateOrdersUser = (index, orderIndex) => (e) => {
        let u = {...users[index]}
        const pi = parseInt(e.target.value, 10)
        if (isNaN(pi)) {
            u.orders[orderIndex] = 0
        } else {
            u.orders[orderIndex] = pi
        }


        updateUser(u, index)
    }

    return (
        <Fragment>
            {
                error ? <Alert severity="error">{error}</Alert> : null
            }
            <Box component="div"
                 sx={{
                     '& > :not(style)': {m: 1, minWidth: '20ch'},
                     display: 'flex',
                     width: "100%"
                 }}
                 autoComplete="off">
                <RoundSelect roundType={roundTypes[0]} setRoundType={updateRoundTypesArray(0)} keyID={0} label="First Round" />
                <RoundSelect roundType={roundTypes[1]} setRoundType={updateRoundTypesArray(1)} keyID={1} label="Second Round" />
                <RoundSelect roundType={roundTypes[2]} setRoundType={updateRoundTypesArray(2)} keyID={2} label="Third Round" />
            </Box>
            <Box component="div"
                 sx={{
                     '& > :not(style)': {m: 1, minWidth: '20ch'},
                     display: 'flex',
                     alignItems: 'center',
                     width: "100%"
                 }}
                 autoComplete="off">
                <div style={{
                    "color": users.find((item) => {
                        return item.elo < MinElo || item.elo > MaxElo
                    }) ? "red" : "green"
                }}>Min: {MinElo} | Max: {MaxElo}
                </div>
                <div style={{
                    "color": users.find((item) => {
                        return item.game_count < 0
                    }) ? "red" : "green"
                }}>Min: 0
                </div>
                <Box component="div"
                     sx={{
                         '& > :not(style)': {m: 1, minWidth: '1rem'},
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'space-around',
                     }}
                     autoComplete="off">
                    <div>
                        {users.filter(item => item.lost_round === 1).length}
                        /
                        {users.length - BettingRewardStructure[users.length][0]}
                    </div>
                    <div>
                        {users.filter(item => item.lost_round === 2).length}
                        /
                        {BettingRewardStructure[users.length][0] - BettingRewardStructure[users.length][1]}
                    </div>
                    <div>
                        {users.filter(item => item.lost_round === 3).length}
                        /
                        {BettingRewardStructure[users.length][1] - BettingRewardStructure[users.length][2]}
                    </div>
                    <div>
                        {users.filter(item => item.lost_round === 4).length}
                        /
                        {BettingRewardStructure[users.length][2]}
                    </div>
                </Box>
                <Box sx={{
                    '& > :not(style)': {m: 1, minWidth: '10ch'},
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    color: "green"
                }}>
                    <InfoButton buttonText={"Orders (Info)"} infoText={"For calculation Elo it matters are you finished 3rd or 4th, although from both positions you qualifies further. (Bigger order = highest position)"}/>
                </Box>
            </Box>
            {
                users.map((item, index) => {
                    return (
                        <Box component="div" key={index}
                             sx={{
                                 '& > :not(style)': {m: 1, minWidth: '20ch'},
                                 display: 'flex',
                                 width: "100%"
                             }}
                             autoComplete="off">
                            <TextField id={`Elo #${index + 1}`} label="Elo" variant="standard" type="number"
                                       value={item.elo} onChange={updateGameCountUser(index, "elo")}/>
                            <TextField id={`Game Count #${index + 1}`} label="Game Count" variant="standard"
                                       type="number"
                                       value={item.game_count} onChange={updateGameCountUser(index, "game_count")}/>

                            <Checkboxes checkedIndex={item.lost_round} setCheckedIndex={(i) => {
                                let u = {...users[index]}
                                u.lost_round = i

                                updateUser(u, index)
                            }}/>

                            <Box component="div" key={index}
                                 sx={{
                                     '& > :not(style)': {m: 1, minWidth: '2rem'},
                                     display: 'flex',
                                     width: "100%"
                                 }}
                                 autoComplete="off">
                                <TextField id={`Orders #${index + 1}-1`} label="Orders #1" variant="standard" type="number"
                                           value={item.orders[0]} onChange={updateOrdersUser(index, 0)}/>
                                <TextField id={`Orders #${index + 1}-1`} label="Orders #2" variant="standard" type="number"
                                           value={item.orders[1]} onChange={updateOrdersUser(index, 1)}/>
                                <TextField id={`Orders #${index + 1}-1`} label="Orders #3" variant="standard" type="number"
                                           value={item.orders[2]} onChange={updateOrdersUser(index, 2)}/>
                            </Box>

                            {
                                lastResponse.length > index ? (
                                    <Box component="div"
                                         sx={{
                                             '& > :not(style)': {m: 1, minWidth: '10ch'},
                                             display: 'flex',
                                             alignItems: 'center',
                                             fontWeight: 'bold',
                                             color: "green"
                                         }}
                                         autoComplete="off">
                                        <div> Elo: {lastResponse[index].elo}</div>
                                        <div> Odd: {lastResponse[index].coefficient.toPrecision(6)}</div>
                                    </Box>
                                ) : null
                            }
                        </Box>
                    )
                })
            }
            <BoxRow>
                <Button variant="contained" onClick={addUser}>Add User</Button>
                <Button variant="contained" onClick={removeUser}>Remove User</Button>
                <Button variant="contained" onClick={localPredict}>Predict</Button>
            </BoxRow>
        </Fragment>
    )
}

export {
    EloPredictor
}

const Checkboxes = ({checkedIndex, setCheckedIndex}) => {
    const isChecked = (i) => {
        return checkedIndex === i
    }

    const localSetCheckedIndex = (i) => (e) => e.target.checked ? setCheckedIndex(i) : null

    return (
        <Box component="div"
             sx={{
                 '& > :not(style)': {m: 1, minWidth: '1rem'},
                 display: 'flex',
                 justifyContent: 'space-around',
             }}
             autoComplete="off">
            <Checkbox checked={isChecked(1)} onChange={localSetCheckedIndex(1)}/>
            <Checkbox checked={isChecked(2)} onChange={localSetCheckedIndex(2)}/>
            <Checkbox checked={isChecked(3)} onChange={localSetCheckedIndex(3)}/>
            <Checkbox checked={isChecked(4)} onChange={localSetCheckedIndex(4)}/>
        </Box>
    )
}

const InfoButton = ({buttonText, infoText}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button aria-describedby={id} variant="contained" onClick={handleClick}>{buttonText}</Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{p: 2}}>{infoText}</Typography>
            </Popover>
        </div>
    );
}

const RoundSelect = ({roundType, setRoundType, keyID, label}) => {
    const types = {
        1: "Race",
        2: "Point",
        3: "Survival"
    }

    return (
        <FormControl fullWidth>
            <InputLabel id={`round-si-${keyID}`}>{label}</InputLabel>
            <Select
                labelId={`round-si-${keyID}`}
                id={`round-s-${keyID}`}
                value={roundType}
                label="Round Type"
                onChange={setRoundType}
            >
                <MenuItem value={1}>{types[1]}</MenuItem>
                <MenuItem value={2}>{types[2]}</MenuItem>
                <MenuItem value={3}>{types[3]}</MenuItem>
            </Select>
        </FormControl>
    )
}