import {useState} from "react";
import {Box, Button, TextField} from "@mui/material";
import {ServerBaseURL} from "../../api/lobby";


export const RecursiveRun = ({url}) => {
    const [count, setCount] = useState(3);

    const link = `${ServerBaseURL}/recursive_iframe?count=${count}&link=${encodeURIComponent(url)}`

    return (
        <Box display="flex" sx={{width: 700, mt: "2rem"}}>
            <TextField fullWidth id="recursiveCount" label="recursiveCount" variant="outlined" value={count}
                       type="number"
                       onChange={(e) => setCount(e.target.value)}/>
            <a href={link} target="_blank" rel="noreferrer">
                <Button  variant="contained" sx={{ml: "2rem"}}>
                    Run Recursive Iframe
                </Button>
            </a>
        </Box>
    );
}