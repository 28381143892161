import {Fragment, useEffect, useState} from "react";
import {GetGamesMap} from "../../api/lobby";
import {EnvSwitch} from "../EnvSwitch";
import {Alert, Box, CircularProgress, FormControl, InputLabel, Link, MenuItem, Select} from "@mui/material";
import {DemoIntegrator} from "../DemoIntegrator";
import {BetsonIntegrator} from "../BetsonIntegrator";
import {CurrencySelect} from "../CurrencySelect";
import {LanguageSelect} from "../LanguageSelect";

const DemoIntegratorName = "Demo"
const BetsonIntegratorName = "Betson"

const GameSelector = () => {
    const [map, setMap] = useState({})
    const [failedEnvs, setFailedEnvs] = useState({})
    const [selectedSwitch, setSelectedSwitch] = useState(0)
    const [integratorSwitch, setIntegratorSwitch] = useState(0)
    const [gameIndex, setGameIndex] = useState(0)
    const [link, setLink] = useState(null)
    const [currency, setCurrency] = useState("usd")
    const [language, setLanguage] = useState("")

    const envs = Object.keys(map)
    const integrators = [DemoIntegratorName, BetsonIntegratorName]

    useEffect(() => {
        GetGamesMap(({environments, failed_environments}) => {
            setMap(environments)
            setFailedEnvs(failed_environments)
        }, () => {
        })
    }, [])

    if (envs.length === 0) {
        return <CircularProgress/>
    }

    const envConfig = map[envs[selectedSwitch]]
    const gameName = envConfig.games[gameIndex].name
    const gameURL = envConfig.games[gameIndex].url

    const selectedSwitchUpdate = (v) => {
        const cfg = map[envs[v]]
        let gi = cfg.games.findIndex(item => item.name === gameName)

        if (gi === -1) {
            gi = 0
        }

        setGameIndex(gi)
        setSelectedSwitch(v)
    }

    return (
        <Fragment>
            {
                Object.keys(failedEnvs).length > 0 ? Object.entries(failedEnvs).map(([env, err]) => {
                    return <Alert severity="error" key={env} sx={{marginTop: ".5rem"}}>"{env}" Environment Failed: error: {err}</Alert>
                }) : null
            }
            <Box sx={{width: "100%", mt: "2rem"}}>
                <EnvSwitch items={envs} selectedIndex={selectedSwitch} select={selectedSwitchUpdate}/>
            </Box>
            <Box sx={{width: "100%", mt: "2rem"}}>
                <EnvSwitch items={integrators} selectedIndex={integratorSwitch} select={setIntegratorSwitch}/>
            </Box>

            <CurrencySelect currency={currency}
                            setCurrency={setCurrency}
                            currencies={envConfig.currencies}/>

            <LanguageSelect setLanguage={setLanguage} language={language}/>


            <Box sx={{width: "100%", mt: "2rem"}}>
                <FormControl fullWidth>
                    <InputLabel>Game</InputLabel>
                    <Select
                        value={gameIndex}
                        label="Game"
                        onChange={(event) => {
                            setGameIndex(event.target.value);
                        }}
                    >
                        {
                            envConfig.games.map((item, i) => {
                                return (
                                    <MenuItem value={i} key={item.name}>{item.name}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{mt: "2rem"}}>
                <Link href={link} target="_blank" rel="noreferrer">{link}</Link>
            </Box>

            {integrators[integratorSwitch] === DemoIntegratorName ?
                <DemoIntegrator
                    currency={currency}
                    language={language}
                    link={link}
                    setLink={setLink}
                    gameURL={gameURL}
                    gameName={gameName}
                    env={envs[selectedSwitch]}
                /> : null
            }

            {integrators[integratorSwitch] === BetsonIntegratorName ?
                <BetsonIntegrator
                    currency={currency}
                    link={link}
                    setLink={setLink}
                    gameURL={gameURL}
                    gameName={gameName}
                    env={envs[selectedSwitch]}
                /> : null
            }


        </Fragment>
    )
}

export {
    GameSelector
}