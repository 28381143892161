import {ToggleButton, ToggleButtonGroup} from "@mui/material";


const EnvSwitch = ({items, selectedIndex, select}) => {
    return (
        <ToggleButtonGroup
            value={items[selectedIndex]}
            exclusive
            onChange={(event , value) => {
                select(value)
            }}>

            {
                items.map((item, i) => {
                    return (
                        <ToggleButton value={i} key={item} disabled={i === selectedIndex}>{item}</ToggleButton>
                    )
                })
            }
        </ToggleButtonGroup>
    )
}

export {
    EnvSwitch
}