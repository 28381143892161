import {Button, Dialog, DialogTitle} from "@mui/material";
import {useState} from "react";

const CurrencySelect = ({currency, setCurrency, currencies}) => {
    const [openedList, setOpenedList] = useState(false)

    const toggleList = () => {
        setOpenedList(!openedList)
    }

    return (
        <div style={{marginTop: "1rem"}}>
            <Button variant="outlined" onClick={toggleList}>Currency: {currency}</Button>
            <CurrencyDialog
                currency={currency}
                setCurrency={setCurrency}
                currencies={currencies}
                open={openedList}
                onClose={toggleList}
            />
        </div>
    )
}

const CurrencyDialog = ({onClose, open, currency, setCurrency, currencies}) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Select currency</DialogTitle>
            <div style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "space-between",
                maxWidth: "100%",
                flexWrap: "wrap",
                gap: 10
            }}>
                {currencies.map((curr, i) => {
                    const color = curr === currency ? "success" : "info"
                    return (
                        <Button style={{weight: "50px"}}
                                variant="contained"
                                size="sm"
                                color={color}
                                key={i}
                                onClick={() => {
                                    setCurrency(curr)
                                    handleClose()
                                }}>{curr}</Button>
                    )
                })}
            </div>
        </Dialog>
    );
}

export {
    CurrencySelect
}