import {Fragment, useEffect, useState} from "react";
import {Box, Button, TextField} from "@mui/material";

const BetsonIntegrator = ({link, setLink, gameURL, gameName, currency}) => {
    const [userID, setUserID] = useState(Math.round(Math.random() * 1000000000));


    useEffect(() => {
        setLink(`${gameURL}?user_id=${userID}&currency=${currency}&integrator=betson&operator=betson`)
    }, [userID, gameURL, gameName, currency]);

    return (
        <Fragment>
            <Box display="flex" sx={{width: 500, mt: "2rem"}}>
                <TextField fullWidth id="outlined-basic" label="User ID" variant="outlined" value={userID}/>
                <Button contained onClick={() => setUserID(Math.round(Math.random() * 1000000000))} sx={{ml: "2rem"}}>
                    Regenerate
                </Button>
            </Box>
        </Fragment>
    )
}

export {
    BetsonIntegrator
}