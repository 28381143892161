import {Box} from "@mui/material";


const BoxCol = ({children}) => {
    return (
        <Box component="div"
             sx={{
                 '& > :not(style)': {m: 1, minWidth: '25ch'},
                 mt: "3rem",
                 display: 'flex',
                 flexDirection: "column",
                 width: "100%"
             }}
             autoComplete="off">
            {children}
        </Box>
    );
}

const BoxRow = ({children}) => {
    return (
        <Box component="div"
             sx={{
                 '& > :not(style)': {m: 1, minWidth: '25ch'},
                 mt: "3rem",
                 display: 'flex',
                 width: "100%"
             }}
             autoComplete="off">
            {children}
        </Box>
    );
}

export {
    BoxCol, BoxRow
}