import {Button, Dialog, DialogTitle} from "@mui/material";
import {useEffect, useState} from "react";


const LanguageSelect = ({language, setLanguage}) => {
    const [openedList, setOpenedList] = useState(false)

    useEffect(() => {
        setLanguage("en")
    }, []);

    const toggleList = () => {
        setOpenedList(!openedList)
    }

    return (
        <div style={{marginTop: "1rem"}}>
            <Button variant="outlined" onClick={toggleList}>Language: {language}</Button>
            <LanguageDialog
                language={language}
                setLanguage={setLanguage}
                open={openedList}
                onClose={toggleList}
            />
        </div>
    )
}

const LanguageDialog = ({onClose, open, language, setLanguage}) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Select language</DialogTitle>
            <div style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "space-between",
                maxWidth: "100%",
                flexWrap: "wrap",
                gap: 10
            }}>
                {languages.map((curr, i) => {
                    const color = curr === language ? "success" : "info"
                    return (
                        <Button style={{weight: "50px"}}
                                variant="contained"
                                size="sm"
                                color={color}
                                key={i}
                                onClick={() => {
                                    setLanguage(curr)
                                    handleClose()
                                }}>{curr}</Button>
                    )
                })}
            </div>
        </Dialog>
    );
}

export {
    LanguageSelect
}

const languages = [
    "aa",
    "ab",
    "ae",
    "af",
    "ak",
    "am",
    "an",
    "ar",
    "as",
    "av",
    "ay",
    "az",
    "ba",
    "be",
    "bg",
    "bh",
    "bi",
    "bm",
    "bn",
    "bo",
    "br",
    "bs",
    "ca",
    "ce",
    "ch",
    "co",
    "cr",
    "cs",
    "cu",
    "cv",
    "cy",
    "da",
    "de",
    "div",
    "dv",
    "dz",
    "ee",
    "el",
    "en",
    "eo",
    "es",
    "et",
    "eu",
    "fa",
    "ff",
    "fi",
    "fj",
    "fo",
    "fr",
    "fy",
    "ga",
    "gd",
    "gl",
    "gn",
    "gu",
    "gv",
    "ha",
    "he",
    "hi",
    "ho",
    "hr",
    "ht",
    "hu",
    "hy",
    "hz",
    "ia",
    "id",
    "ie",
    "ig",
    "ii",
    "ik",
    "in",
    "io",
    "is",
    "it",
    "iu",
    "iw",
    "ja",
    "ji",
    "jv",
    "jw",
    "ka",
    "kg",
    "ki",
    "kj",
    "kk",
    "kl",
    "km",
    "kn",
    "ko",
    "kok",
    "kr",
    "ks",
    "ku",
    "kv",
    "kw",
    "ky",
    "kz",
    "la",
    "lb",
    "lg",
    "li",
    "ln",
    "lo",
    "ls",
    "lt",
    "lu",
    "lv",
    "mg",
    "mh",
    "mi",
    "mk",
    "ml",
    "mn",
    "mo",
    "mr",
    "ms",
    "mt",
    "my",
    "na",
    "nb",
    "nd",
    "ne",
    "ng",
    "nl",
    "nn",
    "no",
    "nr",
    "ns",
    "nv",
    "ny",
    "oc",
    "oj",
    "om",
    "or",
    "os",
    "pa",
    "pi",
    "pl",
    "ps",
    "pt",
    "qu",
    "rm",
    "rn",
    "ro",
    "ru",
    "rw",
    "sa",
    "sb",
    "sc",
    "sd",
    "se",
    "sg",
    "sh",
    "si",
    "sk",
    "sl",
    "sm",
    "sn",
    "so",
    "sq",
    "sr",
    "ss",
    "st",
    "su",
    "sv",
    "sw",
    "sx",
    "syr",
    "ta",
    "te",
    "tg",
    "th",
    "ti",
    "tk",
    "tl",
    "tn",
    "to",
    "tr",
    "ts",
    "tt",
    "tw",
    "ty",
    "ug",
    "uk",
    "ur",
    "us",
    "uz",
    "ve",
    "vi",
    "vo",
    "wa",
    "wo",
    "xh",
    "yi",
    "yo",
    "za",
    "zh",
    "zu",
]