import {Fragment} from "react";
import {Navbar} from "./components/Navbar";
import {GameSelector} from "./components/GameSelector";
import {Box, Container} from "@mui/material";


export default function App() {
    return (
        <Fragment>
            <Navbar/>
            <Container fixed>
                <Box component="div"
                    sx={{ display: 'block', m: '2rem' }}
                >
                    <GameSelector/>
                </Box>
            </Container>
        </Fragment>
    )
}
