import {CardMedia} from "@mui/material";
import {useEffect, useRef, useState} from "react";

const GameIframe = ({link, aspectRatioHeight = 9, aspectRatioWidth = 16, sx, isHidden}) => {
    const ref = useRef();
    const [height, setHeight] = useState(0);
    const [alreadyShown, setAlreadyShown] = useState(false)

    useEffect(() => {
        if (!isHidden && !alreadyShown) {
            setAlreadyShown(!isHidden)
        }
    }, [link, isHidden, alreadyShown])


    useEffect(() => {
        if (ref.current) {
            setHeight(ref.current.getBoundingClientRect().width * aspectRatioHeight / aspectRatioWidth)
        }
    }, [ref, isHidden, aspectRatioHeight, aspectRatioWidth])

    const hiddenClass = isHidden ? {display: "none", visibility: "hidden"} : {}

    if (!alreadyShown && isHidden) {
        return null
    }

    return (
        <CardMedia component='iframe'
                   src={link}
                   ref={ref}
                   sx={{ objectFit: "contain", width: "100%",
                       border: "none", borderRadius: "0.25rem",
                       height: `${height}px`,
                       ...sx, ...hiddenClass}}
        ></CardMedia>
    )
}

export {
    GameIframe
}