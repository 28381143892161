const PrepareDailyBonusPath = "/api/game/cheats/daily_bonus/prepare"
const AddDailyBonusPath = "/api/game/cheats/daily_bonus/add"
const AddXPPath = "/api/game/cheats/add_xp"
const AddGemsBalancePath = "/api/game/cheats/add_gems"
const UnlockEverythingPath = "/api/game/cheats/unlock_everything"
const SpendRealMoneyPath = "/api/game/cheats/spend_real_money"
const GetEloQuartilesPath = "/api/game/cheats/elo/quartiles"
const SetEloRankingPath = "/api/game/cheats/elo/set_ranking"
const PredictGameResultPath = "/api/game/cheats/tools/predict_game_result"

const LaunchPath = "/api/game/launch"

const prepareDailyBonus = (apiPath, userID, dayIndex) => (onSuccess, onFailure) => {
    const body = JSON.stringify({
        "user_id": userID,
        "day_index": parseInt(dayIndex, 10)
    });


    fetch(cashRunPath(apiPath, PrepareDailyBonusPath), getRequestOptions(body))
        .then(response => response.json())
        .then(b => {
            if (b.status >= 400) {
                onFailure(b.data)
            } else {
                onSuccess(b.data)
            }
        })
        .catch(onFailure);
}

const addDailyBonus = (apiPath, userID, dayIndex) => (onSuccess, onFailure) => {
    const body = JSON.stringify({
        "user_id": userID,
        "day_index": parseInt(dayIndex, 10)
    });

    fetch(cashRunPath(apiPath, AddDailyBonusPath), getRequestOptions(body))
        .then(response => response.json())
        .then(b => {
            if (b.status >= 400) {
                onFailure(b.data)
            } else {
                onSuccess(b.data)
            }
        })
        .catch(onFailure);
}

const addXP = (apiPath, userID, count) => (onSuccess, onFailure) => {
    const body = JSON.stringify({
        "user_id": userID,
        "count": parseInt(count, 10)
    });

    fetch(cashRunPath(apiPath, AddXPPath), getRequestOptions(body))
        .then(response => response.json())
        .then(b => {
            if (b.status >= 400) {
                onFailure(b.data)
            } else {
                onSuccess(b.data)
            }
        })
        .catch(onFailure);
}

const addGemsBalance = (apiPath, userID, count) => (onSuccess, onFailure) => {
    const body = JSON.stringify({
        "user_id": userID,
        "count": parseInt(count, 10)
    });

    fetch(cashRunPath(apiPath, AddGemsBalancePath), getRequestOptions(body))
        .then(response => response.json())
        .then(b => {
            if (b.status >= 400) {
                onFailure(b.data)
            } else {
                onSuccess(b.data)
            }
        })
        .catch(onFailure);
}

const unlockEverything = (apiPath, userID) => (onSuccess, onFailure) => {
    const body = JSON.stringify({
        "user_id": userID,
    });

    fetch(cashRunPath(apiPath, UnlockEverythingPath), getRequestOptions(body))
        .then(response => response.json())
        .then(b => {
            if (b.status >= 400) {
                onFailure(b.data)
            } else {
                onSuccess(b.data)
            }
        })
        .catch(onFailure);
}

const spendRealMoney = (apiPath, userID, count) => (onSuccess, onFailure) => {
    const body = JSON.stringify({
        "user_id": userID,
        "count": parseInt(count, 10)
    });

    fetch(cashRunPath(apiPath, SpendRealMoneyPath), getRequestOptions(body))
        .then(response => response.json())
        .then(b => {
            if (b.status >= 400) {
                onFailure(b.data)
            } else {
                onSuccess(b.data)
            }
        })
        .catch(onFailure);
}

const getEloQuartiles = (apiPath) => (onSuccess, onFailure) => {
    const body = JSON.stringify({});

    fetch(cashRunPath(apiPath, GetEloQuartilesPath), getRequestOptions(body))
        .then(response => response.json())
        .then(b => {
            console.log("!!!")
            if (b.status >= 400) {
                onFailure(b.data)
            } else {
                onSuccess(b.data)
            }
        })
        .catch(err => {
            onFailure(err.toString())
        });
}

const setEloRanking = (apiPath, userID, elo) => (onSuccess, onFailure) => {
    const body = JSON.stringify({
        "user_id": userID,
        "elo": parseInt(elo, 10)
    });

    fetch(cashRunPath(apiPath, SetEloRankingPath), getRequestOptions(body))
        .then(response => response.json())
        .then(b => {
            if (b.status >= 400) {
                onFailure(b.data)
            } else {
                onSuccess(b.data)
            }
        })
        .catch(onFailure);
}

const cashRunLaunch = (apiPath,currency, externalUserID) => async (callWithUserID) => {
    const body = JSON.stringify({
        "currency": currency,
        "game": "cash-run",
        "integrator": "demo",
        "operator": "demo",
        "user_id": externalUserID
    });

    const resp = await fetch(cashRunPath(apiPath, LaunchPath), getRequestOptions(body))
    const b = await resp.json();

    await callWithUserID(b.data.id)
}

const predict = (apiPath, users, roundTypes) => (onSuccess, onFailure) => {
    const body = JSON.stringify({
        items: users,
        round_types: roundTypes
    });

    fetch(cashRunPath(apiPath, PredictGameResultPath), getRequestOptions(body))
        .then(response => response.json())
        .then(b => {
            if (b.status >= 400) {
                onFailure(b.data)
            } else {
                onSuccess(b.data)
            }
        })
        .catch(onFailure);
}

export {
    prepareDailyBonus,
    addDailyBonus,
    addXP,
    addGemsBalance,
    unlockEverything,
    spendRealMoney,
    cashRunLaunch,
    getEloQuartiles,
    setEloRanking,
    predict
}

const cashRunPath = (basePath, path) => {
    if (basePath.length > 0 && basePath[basePath.length - 1] === "/") {
        basePath = basePath.slice(0, basePath.length - 1)
    }

    if (path.length > 0 && path[0] === "/") {
        path = path.slice(1)
    }

    return `${basePath}/${path}`
}

const getHeaders = () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    return headers
}

const getRequestOptions = (body) => {
    return {
        method: 'POST',
        headers: getHeaders(),
        body: body,
        redirect: 'follow'
    };
}
