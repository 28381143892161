const ServerBaseURL = "https://lobby.gameinc.studio/api"
const GamesMapPath = "/games"
const CreateFreeBetPath = "/create_free_bets"

const GetGamesMap = (setGames, setError) => {
    fetch(ServerBaseURL + GamesMapPath)
        .then(r => r.json())
        .then(b => setGames(b.data))
        .catch(err => setError(err))
}

const CreateFreeBet = (env, user_id, game, currency, wager, count, minMultiplier, maxTotalAwards, subtractAward, deferredPayment) => (setSuccess, setError) => {
    fetch(ServerBaseURL + CreateFreeBetPath, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },

        body: JSON.stringify({
            env: env,
            user_id: user_id,
            game: game,
            currency: currency,
            wager: parseInt(wager, 10),
            count: parseInt(count, 10),
            min_multiplier: minMultiplier,
            max_total_award: maxTotalAwards,
            subtract_award: subtractAward,
            deferred_payment: deferredPayment,
        }),
    })
        .then(r => r.json())
        .then(b => b.success ? setSuccess(b.data) : setError(b.data))
        .catch(err => setError(err))
}

export {GetGamesMap, CreateFreeBet, ServerBaseURL}