import {useEffect, useState} from "react";
import {Alert, Box, Button, TextField} from "@mui/material";
import {
    addDailyBonus,
    addGemsBalance,
    addXP,
    cashRunLaunch,
    getEloQuartiles,
    prepareDailyBonus,
    setEloRanking,
    spendRealMoney,
    unlockEverything
} from "../../api/cash_run";
import {EloPredictor} from "../EloPredictor";


const CashRunCheats = ({name, gameURL, externalUserID, currency}) => {
    const [dayIndex, setDayIndex] = useState(0)
    const [xp, setXP] = useState(0)
    const [gemsBalance, setGemsBalance] = useState(0)
    const [spendRealVal, setSpendRealVal] = useState(0)
    const [eloLocalRanking, setEloLocalRanking] = useState(null)
    const [ownRanking, setOwnRanking] = useState(2000)
    const [error, setError] = useState("")

    useEffect(() => {
        if (name === "cash-run") {
            getEloQuartiles(gameURL)(setEloLocalRanking, setError)
        }
    }, [gameURL, name])


    if (name !== "cash-run") {
        return null
    }

    const resetError = () => {
        setError("")
    }

    const preLaunch = async (callWithUserID) => {
        resetError()
        await cashRunLaunch(gameURL, currency, externalUserID)(callWithUserID)
    }

    const dailyPrepare = () => {
        preLaunch((userID) => {
            prepareDailyBonus(gameURL, userID, dayIndex)(console.log, setError)
        })
    }

    const dailyAdd = () => {
        preLaunch((userID) => addDailyBonus(gameURL, userID, dayIndex)(console.log, setError))
    }

    const xpAdd = () => {
        preLaunch((userID) => addXP(gameURL, userID, xp)(console.log, setError))
    }

    const gemsBalanceAdd = () => {
        preLaunch((userID) => addGemsBalance(gameURL, userID, gemsBalance)(console.log, setError))
    }

    const unlockAll = () => {
        preLaunch((userID) => unlockEverything(gameURL, userID)(console.log, setError))
    }

    const spendReal = () => {
        preLaunch((userID) => spendRealMoney(gameURL, userID, spendRealVal)(console.log, setError))
    }

    const eloOwnRanking = () => {
        preLaunch((userID) => setEloRanking(gameURL, userID, ownRanking)(console.log, setError))
    }


    return (
        <Box
            component="div"
            sx={{
                '& > :not(style)': {m: 1, minWidth: '25ch'},
                mt: "3rem"
            }}
            noValidate
            autoComplete="off"
        >
            <h1>Cheats</h1>
            {
                error ? <Alert severity="error">{error}</Alert> : null
            }
            <h2>Daily Bonus</h2>
            <Box component="div"
                 sx={{'& > :not(style)': {m: 1, minWidth: '25ch'}, mt: "3rem", display: 'flex', width: "100%"}}
                 autoComplete="off">
                <TextField id="day-index" label="dayIndex" variant="standard" type="number"
                           value={dayIndex} onChange={(e) => setDayIndex(e.target.value)}/>
                <Button variant="contained" onClick={dailyPrepare}>Prepare</Button>
                <Button variant="contained" onClick={dailyAdd}>Add</Button>
            </Box>
            <h2>Add XP</h2>
            <Box component="div"
                 sx={{'& > :not(style)': {m: 1, minWidth: '25ch'}, mt: "3rem", display: 'flex', width: "100%"}}
                 autoComplete="off">
                <TextField id="xp" label="xp" variant="standard" type="number"
                           value={xp} onChange={(e) => setXP(e.target.value)}/>
                <Button variant="contained" onClick={xpAdd}>Submit</Button>
            </Box>

            <h2>Add Gems Balance</h2>
            <Box component="div"
                 sx={{'& > :not(style)': {m: 1, minWidth: '25ch'}, mt: "3rem", display: 'flex', width: "100%"}}
                 autoComplete="off">
                <TextField id="gemsBalance" label="gemsBalance" variant="standard" type="number"
                           value={gemsBalance} onChange={(e) => setGemsBalance(e.target.value)}/>
                <Button variant="contained" onClick={gemsBalanceAdd}>Submit</Button>
            </Box>

            <h2>Unlock Everything</h2>
            <Box component="div"
                 sx={{'& > :not(style)': {m: 1, minWidth: '25ch'}, mt: "3rem", display: 'flex', width: "100%"}}
                 autoComplete="off">
                <Button variant="contained" onClick={unlockAll}>Submit</Button>
            </Box>

            <h2>Spend Real Money</h2>
            <Box component="div"
                 sx={{'& > :not(style)': {m: 1, minWidth: '25ch'}, mt: "3rem", display: 'flex', width: "100%"}}
                 autoComplete="off">
                <TextField id="spendRealVal" label="spendRealVal" variant="standard" type="number"
                           value={spendRealVal} onChange={(e) => setSpendRealVal(e.target.value)}/>
                <Button variant="contained" onClick={spendReal}>Submit</Button>
            </Box>

            <h2>Elo</h2>
            <Box component="div"
                 sx={{'& > :not(style)': {m: 1, minWidth: '25ch'}, mt: "3rem", display: 'flex', width: "100%"}}
                 autoComplete="off">
                <Box>{eloLocalRanking ?
                    <h3>Q1: {eloLocalRanking.q1} | Q2: {eloLocalRanking.q2} |
                        Q3: {eloLocalRanking.q3} </h3> : null}</Box>
                <br/>

            </Box>
            <Box component="div"
                 sx={{'& > :not(style)': {m: 1, minWidth: '25ch'}, mt: "3rem", display: 'flex', width: "100%"}}
                 autoComplete="off">
                <TextField id="Own ranking" label="Own ranking" variant="standard" type="number"
                           value={ownRanking} onChange={(e) => setOwnRanking(e.target.value)}/>
                <Button variant="contained" onClick={eloOwnRanking}>Set own ranking</Button>
            </Box>

            <h2>Elo Predict calculation</h2>
            <Box>
                <EloPredictor apiPath={gameURL}/>
            </Box>
        </Box>
    )
}

export {
    CashRunCheats
}