import {
    Alert,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel, Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import {Fragment, useState} from "react";
import {CreateFreeBet} from "../../api/lobby";
import {BoxCol, BoxRow} from "../Box";

const setterNumberOrNull = (setter) => (e) => {
    const value = e.target.value
    const intValue = parseInt(value, 10)

    console.log(intValue, intValue > 0)

    if (!isNaN(intValue) && intValue > 0) {
        setter(intValue)
    } else {
        setter(null)
    }
}

const FreeBetsControl = ({userID, game, env, currency}) => {
    const [wager, setWager] = useState(1000)
    const [count, setCount] = useState(10)
    const [minMultiplier, setMinMultiplier] = useState(null)
    const [maxTotalAwards, setMaxTotalAwards] = useState(null)
    const [subtractAward, setSubtractAward] = useState(null)
    const [deferredPayment, setDeferredPayment] = useState(null)

    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const setNull = (setter) => () => {
        setter(null)
    }

    const setFalse = (setter) => () => {
        setter(false)
    }

    const setTrue = (setter) => () => {
        setter(true)
    }

    const reset = () => {
        setSuccess(null)
        setError(null)
    }

    const createFreeBets = () => {
        reset()
        CreateFreeBet(env, userID, game, currency, wager, count, minMultiplier, maxTotalAwards, subtractAward, deferredPayment)(setSuccess, setError)
    }

    return (
        <Fragment>
            <h2>Free bets</h2>

            {
                error ? <Alert severity="error">{error}</Alert> : null
            }

            {
                success ? <Alert severity="success">{success}</Alert> : null
            }

            <BoxCol>
                <h3>Required</h3>

                <BoxRow>
                    <TextField id="wager" label="wager" variant="standard" type="number"
                               value={wager} onChange={(e) => setWager(e.target.value)}/>
                    <TextField id="count" label="count" variant="standard" type="number"
                               value={count} onChange={(e) => setCount(e.target.value)}/>
                </BoxRow>

                <h3>Optional</h3>

                <BoxRow>
                    <TextField id="min-multiplier" label="min multiplier" variant="standard" type="number"
                               value={minMultiplier || ""} onChange={setterNumberOrNull(setMinMultiplier)}/>
                    <TextField id="max-total-award" label="max total award" variant="standard" type="number"
                               value={maxTotalAwards || ""} onChange={setterNumberOrNull(setMaxTotalAwards)}/>
                </BoxRow>

                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Subtract Award</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={subtractAward}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel checked={subtractAward === null} control={<Radio />} label="Null (use default for game)" onChange={setNull(setSubtractAward)} />
                        <FormControlLabel checked={subtractAward === true} control={<Radio />} label="True" onChange={setTrue(setSubtractAward)} />
                        <FormControlLabel checked={subtractAward === false} control={<Radio />} label="False" onChange={setFalse(setSubtractAward)} />
                    </RadioGroup>
                </FormControl>

                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Deferred Payment</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel  checked={deferredPayment === null} control={<Radio />} label="Null (use default for game)" onChange={setNull(setDeferredPayment)} />
                        <FormControlLabel  checked={deferredPayment === true} control={<Radio />} label="True" onChange={setTrue(setDeferredPayment)} />
                        <FormControlLabel  checked={deferredPayment === false} control={<Radio />} label="False" onChange={setFalse(setDeferredPayment)} />
                    </RadioGroup>
                </FormControl>

                <BoxRow>
                    <Button variant="contained" onClick={createFreeBets}>Add</Button>
                </BoxRow>
            </BoxCol>
        </Fragment>
    )
}

export {FreeBetsControl}